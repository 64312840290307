import React, { Component } from "react"
import { StaticImage } from "gatsby-plugin-image"

export default class App extends Component {
  render() {
    return (
      <StaticImage
        src="../assets/images/ideality.png"
        alt="contact"
        className="hannyaSpace"
        placeholder="tracedSVG"
        layout="fullWidth"
        quality="100"
      ></StaticImage>
    )
  }
}
